import $ from 'jquery';
import store from '@/state/store.js';
import messageModal from '../messages/messageModal.vue';
import moment from 'moment';
export default {
  name: 'api-v1',
  components: {
    messageModal
  },
  data() {
    return {
      createLoading: false,
      createError: '',
      type: 'alert-danger',
      apiKeys: [],
      from: '',
      dataPayload: {}
    };
  },
  methods: {
    deleteSuccess(data) {
      this.apiKeys.splice(this.dataPayload.selectedKey, 1);
    },
    opendModal({
      message,
      key
    }) {
      this.dataPayload = {
        currentModal: 'delete',
        selectedKey: key,
        selectedMessage: message,
        deleteType: '_deleteApiV1Key',
        deleteMessage: 'This will remove the Api Key permanently.'
      };
      store.dispatch('uistate/addContactFormData', {
        title: "",
        type: 'delete'
      });
      let element = this.$refs.messageModal.$el;
      $(element).modal('show');
    },
    createApiV1() {
      this.createLoading = true;
      this.createError = '';
      store.dispatch('developer/createApiV1').then(data => {
        window.location.reload();
      }).catch(err => this.createError = "An error occured. Try again").finally(() => this.createLoading = false);
    },
    async getApiV1() {
      const data = await store.dispatch('developer/getApiV1');
      this.apiKeys = await data;
    }
  },
  mounted() {
    this.getApiV1();
  }
};