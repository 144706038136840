var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "mnotify-card"
  }, [_vm._m(0), _c('div', {}, [_c('div', {
    staticClass: "campaigne-card-inner"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-md-8 contains-text"
  }, [_c('div', {
    staticClass: "row __main_text_"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('el-button', {
    staticClass: "create_api_key",
    attrs: {
      "loading": _vm.createLoading
    },
    on: {
      "click": _vm.createApiV1
    }
  }, [_vm._v(" Create API Key ")])], 1), _c('div', {
    staticClass: "col-md-12"
  }, [_vm.createError ? _c('Alert', {
    attrs: {
      "message": _vm.createError,
      "type": _vm.type
    }
  }) : _vm._e()], 1)])]), _vm.apiKeys.length ? _c('div', {
    staticClass: "__reporting__table"
  }, [_c('table', {
    staticClass: "__mnotify__table"
  }, [_vm._m(1), _c('tbody', {
    staticClass: "__mnotify__table__body"
  }, _vm._l(_vm.apiKeys, function (j, key) {
    return _c('tr', {
      key: key,
      staticClass: "__table__with__border"
    }, [_c('td', {
      staticClass: "mnotify__table__first__item",
      staticStyle: {
        "cursor": "pointer"
      }
    }, [_vm._v(" " + _vm._s(j.key) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm.moment(j.created_at).format('llll')) + " ")]), _c('td', [_c('button', {
      staticClass: "delete",
      on: {
        "click": function ($event) {
          return _vm.opendModal({
            message: j,
            key: key
          });
        }
      }
    }, [_c('img', {
      attrs: {
        "width": "20px",
        "height": "20px",
        "src": require("@/assets/__delete.svg"),
        "alt": "bin icon for delete"
      }
    })])])]);
  }), 0)])]) : _vm._e(), _vm._m(2), _vm._m(3), _vm._m(4)])])])])])])])]), _c('messageModal', {
    ref: "messageModal",
    attrs: {
      "payload": _vm.dataPayload,
      "from": _vm.from
    },
    on: {
      "deleteSuccess": _vm.deleteSuccess
    }
  })], 1)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "mnotify-card-header d-flex justify-content-between align-items-center"
  }, [_c('h3', {
    staticClass: "mnotify-card-header-text"
  }, [_vm._v("API v.1.0")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('thead', {
    staticClass: "__mnotify__table__header"
  }, [_c('tr', [_c('th', {
    staticClass: "__mnotify__table__header_first",
    staticStyle: {
      "width": "40%"
    }
  }, [_vm._v("Key")]), _c('th', {
    staticStyle: {
      "width": "40%"
    }
  }, [_vm._v("Date Created")]), _c('th', {
    staticStyle: {
      "width": "20%"
    }
  }, [_vm._v("Action")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "col-md-12 __api_text"
  }, [_c('h1', [_vm._v("Getting Started")]), _c('h2', [_vm._v(" Sending SMS ")]), _c('p', [_vm._v(" Endpoint: https://apps.mnotify.net/smsapi?key=xxxxxxxxxx&to=xxxxxxx&msg=xxxxxxxx&sender_id=xxxxx ")]), _c('p', [_vm._v(" Error Codes: ")]), _c('ul', [_c('li', [_vm._v("1000 = Message submited successful")]), _c('li', [_vm._v("1002 = SMS sending failed")]), _c('li', [_vm._v("1003 = insufficient balance")]), _c('li', [_vm._v("1004 = invalid API key")]), _c('li', [_vm._v(" 1005 = invalid Phone Number ")]), _c('li', [_vm._v("1006 = invalid Sender ID. Sender ID must not be more than 11 Characters. Characters include white space.")]), _c('li', [_vm._v("1007 = Message scheduled for later delivery")]), _c('li', [_vm._v("1008 = Empty Message")]), _c('li', [_vm._v("1009 = Empty from date and to date")]), _c('li', [_vm._v("1010 = No mesages has been sent on the specified dates using the specified api key")]), _c('li', [_vm._v("1011 = Numeric Sender IDs are not allowed")]), _c('li', [_vm._v("1012 = Sender ID is not registered. Please contact our support team via senderids@mnotify.com or call 0541509394 for assistance")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "col-md-12 __api_text"
  }, [_c('h2', [_vm._v(" Checking Balance ")]), _c('p', [_vm._v(" Endpoint: https://apps.mnotify.net/smsapi/balance?key=xxxxxxxxxx ")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "col-md-12 __api_text"
  }, [_c('h2', [_vm._v(" Checking Balance Usage on API KEY ")]), _c('p', [_vm._v(" Endpoint: https://apps.mnotify.net/smsapi/api_usage?key=xxxxxxxxxx&from=Y-M-D H:s:i&to=Y-M-D H:s:i ")]), _c('p', [_vm._v(" Visit: Video Tutorial for the step by step process ")])]);
}];
export { render, staticRenderFns };